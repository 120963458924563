import React from 'react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/autoplay';

const MySwiper = () => {
  return (
    <Swiper
      modules={[Navigation, Pagination, Scrollbar, A11y]}
      spaceBetween={5}
      slidesPerView={3}
      loop={true}
      navigation
      autoplay={true}
      pagination={{ clickable: true }}
      scrollbar={{ draggable: true }}
      onSwiper={(swiper) => console.log(swiper)}
      onSlideChange={() => console.log('slide change')}
    >
      <SwiperSlide><div className="carouselPics"><img src="https://i.imgur.com/D7C6pZH.jpg" alt="" /></div></SwiperSlide>
      <SwiperSlide><div className="carouselPics"><img src="https://i.imgur.com/sWMG84R.jpg" alt="" /></div></SwiperSlide>
      <SwiperSlide><div className="carouselPics"><img src="https://i.imgur.com/UbGmaGg.jpg" alt="" /></div></SwiperSlide>
      <SwiperSlide><div className="carouselPics"><img src="https://i.imgur.com/u1yZnC9.jpg" alt="" /></div></SwiperSlide>
      <SwiperSlide><div className="carouselPics"><img src="https://i.imgur.com/Jk3UqR5.jpg" alt="" /></div></SwiperSlide>
      <SwiperSlide><div className="carouselPics"><img src="https://i.imgur.com/wDetZ0P.jpg" alt="" /></div></SwiperSlide>
      <SwiperSlide><div className="carouselPics"><img src="https://i.imgur.com/nSbcajq.jpg" alt="" /></div></SwiperSlide>
      <SwiperSlide><div className="carouselPics"><img src="https://i.imgur.com/uykTV8M.jpg" alt="" /></div></SwiperSlide>
      <SwiperSlide><div className="carouselPics"><img src="https://i.imgur.com/8zqXT7K.jpg" alt="" /></div></SwiperSlide>
      <SwiperSlide><div className="carouselPics"><img src="https://i.imgur.com/7QIbSxh.jpg" alt="" /></div></SwiperSlide>
    </Swiper>
  );
};

export default MySwiper;
