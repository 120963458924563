import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import AddItem from '../Components/AddItem'; // Updated import to AddItem
import axios from 'axios';
import { API_BASE_URL } from '../apiconfig';

function AdminPage({ gifts, onAddGift }) {
  const [activeButton, setActiveButton] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const [checkedItems, setCheckedItems] = useState({});

  const filteredGifts = activeButton ? gifts.filter(gift => gift.GiftType === activeButton) : [];

  const handleToggleCheck = async (giftId) => {
    setCheckedItems((prevItems) => ({
      ...prevItems,
      [giftId]: !prevItems[giftId],
    }));

    try {
      await axios.put(`${API_BASE_URL}/gifts/toggleStrike/${giftId}`);
    } catch (error) {
      console.error('Error toggling strike:', error);
    }
  };

  const handleDeleteItem = (itemId) => {
    axios.delete(`${API_BASE_URL}/gifts/deleteGift/${itemId}`)
      .then((response) => {
        if (response.data.success) {
          setCheckedItems((prev) => {
            const updated = { ...prev };
            delete updated[itemId];
            return updated;
          });
        } else {
          console.error(response.data.message);
        }
      })
      .catch((error) => {
        console.error('Error deleting item:', error);
      });
  };

  const cardTitle = activeButton ? `${activeButton} Gifts` : "Card Title";

  return (
    <div id='adminDiv'>
      <div id='adminButtons' style={{ marginTop: '100px' }}>
        <Button
          style={{ marginRight: '20px', marginLeft: '10px' }}
          variant="outline-danger"
          size='lg'
          onClick={() => setActiveButton("Men's")}
        >
          Men
        </Button>{' '}
        <Button
          variant="outline-warning"
          size='lg'
          onClick={() => setActiveButton("Women's")}
        >
          Women
        </Button>{' '}
        <Button
          style={{ marginLeft: '20px' }}
          variant="outline-success"
          size='lg'
          onClick={() => setActiveButton("General")}
        >
          General
        </Button>{' '}
        <Button
          style={{ marginLeft: '20px' }}
          variant="outline-primary"
          size="lg"
          onClick={() => setModalShow(true)}
        >
          Add
        </Button>
        <AddItem
          show={modalShow}
          onHide={() => setModalShow(false)}
          onAddGift={onAddGift} // Pass the onAddGift callback
        />
      </div>

      <div id='adminCardDiv'>
        <Card id='adminCard' style={{ width: '22rem', marginTop: '40px' }}>
          <Card.Body>
            <Card.Title>{cardTitle}</Card.Title>
            <Card.Text>
              Keep doing great things
            </Card.Text>
          </Card.Body>
          <ListGroup>
            {filteredGifts.map((gift) => (
              <div key={gift._id} className="d-flex align-items-center">
                <input
                  type="checkbox"
                  checked={checkedItems[gift._id] || false}
                  onChange={() => handleToggleCheck(gift._id)}
                />
                <ListGroup.Item
                  id='adminList'
                  style={{ textDecoration: gift.isStruckOut ? 'line-through' : 'none' }}
                  className="flex-grow-1"
                >
                  {gift.Name} - {gift.Description}
                </ListGroup.Item>
                <Button variant="danger" onClick={() => handleDeleteItem(gift._id)}>
                  Delete
                </Button>
              </div>
            ))}
          </ListGroup>
        </Card>
      </div>
    </div>
  );
}

export default AdminPage;
