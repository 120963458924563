import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Carousel from '../Components/Carousel';
import Card from 'react-bootstrap/Card';

function HomePage() {
  return (
    <div id='homeDiv'>
      <br /><br /><br /><br /><br />

      <Container>
        <Row>
          {/* Column for the Card */}
          <Col xs={12} md={6} lg={8} className="mb-3">
            <Card id='homeCard'>
              <Card.Body>
                <Card.Title>Welcome To Peaceful Pastures Project</Card.Title>
                <br />
                <Card.Text>
                  The Peaceful Pastures Project was initiated in 2022, inspired by the idea of 'adopting a grandparent for Christmas', a concept that resonated deeply with me, having lost all my grandparents and parents. This initiative represented not just an opportunity for a meaningful family Christmas activity, but a chance to bring joy to those who might otherwise feel forgotten during this festive season.
                </Card.Text>
                <Card.Text>
                  In my quest to make a difference, I reached out to a friend and certified nursing assistant at a local nursing home. She presented me with a list of nearly 30 residents, many of whom lacked regular visitors or family connections. The choice was impossible, every individual deserved to feel cherished, especially during a time when holiday-induced depression is prevalent among seniors.
                </Card.Text>
                <Card.Text>
                  Faced with this challenge, I turned to my local community through Facebook groups, seeking assistance. The response was overwhelming. Our incredible community came together to ensure that not only were all 30 seniors 'adopted', but they also received gifts that enhanced their daily lives in the nursing facility. The joy and gratitude witnessed on Christmas Day was profound and reinforced my commitment to this cause.
                </Card.Text>
                <Card.Text>
                  As we enter 2023, the Peaceful Pastures Project has expanded to include two nursing homes. Currently, we have successfully adopted 86 seniors and we have 3 facilities now. This project serves as a reminder that these seniors, who have played vital roles as spouses, parents, and relatives, deserve to feel valued and remembered. Though they may be in the twilight of their lives, our efforts aim to make these years as fulfilling and peaceful as possible.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>

          {/* Column for Images */}
          <Col xs={12} md={6} lg={4}>
            <div className="d-flex justify-content-center">
              <img
                id='homeImage'
                src='https://i.imgur.com/LuGnTzL.jpg'
                alt='PeacefulPastures'
                className="img-fluid"
                style={{ maxWidth: '100%', width: '300px' }}
              />
            </div>
            <div className="d-flex justify-content-center mt-4">
              <img
                id='homeImage'
                src='https://i.imgur.com/mACtela.jpg'
                alt='PeacefulPastures'
                className="img-fluid"
                style={{ maxWidth: '100%', width: '300px' }}
              />
            </div>
          </Col>
        </Row>

        <br /><br /><br /><br /><br />
        <Carousel />
        <br /><br /><br /><br />
      </Container>
    </div>
  );
}

export default HomePage;
