import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useState } from 'react';

function AddItem(props) {
  const [formData, setFormData] = useState({
    Name: '',
    GiftType: "Men's", // Default value to prevent empty initial state
    Description: '',
  });

  const [error, setError] = useState(''); // State to hold error message
  const [isSubmitting, setIsSubmitting] = useState(false); // State to handle submit status

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Validation checks
    if (formData.Name.trim() === '' || formData.Description.trim() === '') {
      // Set the error message
      setError('Please fill out all required fields.');
    } else {
      try {
        // Clear any previous error message
        setError('');
        setIsSubmitting(true); // Disable button while submitting

        // Ensure that onAddGift is a function and call it
        if (typeof props.onAddGift === 'function') {
          await props.onAddGift(formData); // Call the function passed from AdminPage
        } else {
          console.error('onAddGift is not a function');
        }

        // Reset form fields after successful submission
        setFormData({
          Name: '',
          GiftType: "Men's",
          Description: '',
        });

        // Call parent's onHide function to close the modal
        props.onHide();
      } catch (error) {
        console.error('Error adding item:', error);
        setError('Error adding item. Please try again.');
      } finally {
        setIsSubmitting(false); // Re-enable button after submission
      }
    }
  };

  return (
    <Modal {...props} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Add Person and Gift</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit}>
          <label htmlFor='Name'>Name:</label>
          <input
            type='text'
            id='Name'
            name='Name'
            value={formData.Name}
            onChange={(event) => setFormData({ ...formData, Name: event.target.value })}
          />
          <br />
          <label htmlFor='GiftType'>Gift type:</label>
          <select
            id='GiftType'
            name='GiftType'
            value={formData.GiftType}
            onChange={(event) => setFormData({ ...formData, GiftType: event.target.value })}
          >
            <option value="Men's">Men's</option>
            <option value="Women's">Women's</option>
            <option value="General">General</option>
          </select>
          <br />
          <label htmlFor='Description'>Description:</label>
          <input
            type='text'
            id='Description'
            name='Description'
            value={formData.Description}
            onChange={(event) => setFormData({ ...formData, Description: event.target.value })}
          />

          {/* Error message */}
          {error && <p className="text-danger">{error}</p>}

          <Modal.Footer>
            <Button variant='danger' onClick={props.onHide}>
              Close
            </Button>
            <Button variant="success" type='submit' disabled={isSubmitting}>
              Add
            </Button>
          </Modal.Footer>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export default AddItem;
