import React from 'react'
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';


function faq() {
  return (
<div id='faqDiv'>
      <Container id='faqContainer'>
        <div id='spaceAboveCard'></div> 
        <Card id='faqCard'>
      <Card.Body>
        <Card.Title id='faqTitle'>Frequently Asked Questions</Card.Title>
        <br/>
        <Card.Text style={{fontWeight:'bold'}}>
          Question:  What can I gift?
        </Card.Text>
        <Card.Text>
        Answer: You have the freedom to gift anything you want. While we encourage you to fulfill at least the basic items listed, you're welcome to add more. Every thoughtful contribution is valued.
        </Card.Text>
        <hr/>
        <Card.Text style={{fontWeight:'bold'}}>
          Question: What are appropriate soft snacks?
        </Card.Text>
        <Card.Text>
          Answer: Soft snacks like Jello, pudding, and snack cakes (e.g., Swiss rolls, oatmeal cream pies) are great. Light and airy snacks such as veggie straws or cheese puffs also make delightful choices.
        </Card.Text>
        <hr/>
        <Card.Text style={{fontWeight:'bold'}}>
          Question: What clothing types are recommended?
        </Card.Text>
        <Card.Text>
          Answer: We recommend comfortable clothing with stretchy waists for bottoms, and stretchy or buttoned tops for easier dressing. Zip-up or buttoned sweaters and open-front clothing are highly appreciated for their convenience.
        </Card.Text>
        <hr/>
        <Card.Text style={{fontWeight:'bold'}}>
          Question: Can I have the person's name for personalized gifts?
        </Card.Text>
        <Card.Text>
          Answer: Due to privacy laws (HIPAA), we can't provide personal names, only initials. We prioritize the privacy and confidentiality of all individuals.
        </Card.Text>
        <hr/>
        <Card.Text style={{fontWeight:'bold'}}>
          Question: What are the preferred lotions and body washes?
        </Card.Text>
        <Card.Text>
          Answer: Many seniors enjoy fragrant lotions and body washes. We recommend products formulated for dry or diabetic skin. If specific needs or sensitivities are noted, we'll include that in their adoption list.
        </Card.Text>
        <hr/>
        <Card.Text style={{fontWeight:'bold'}}>
          Question: Is it possible to deliver the gift personally?
        </Card.Text>
        <Card.Text>
          Answer: Currently, due to Covid protocols and privacy concerns, personal deliveries are not possible. We aim to offer this in the future as circumstances permit.
        </Card.Text>
        <hr/>
        <Card.Text style={{fontWeight:'bold'}}>
          Question: Can I adopt more than one individual?
        </Card.Text>
        <Card.Text>
          Answer: Absolutely! You're welcome to adopt as many as you feel comfortable with, whether it's one or five. We appreciate your generosity in any form.
        </Card.Text>
      </Card.Body>
      </Card>
      <br/>
      <br/>
      </Container>
    </div>
  )
}

export default faq