import './App.css';
import React from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import GlobalLayout from './Layout/GlobalLayout';
import HomePage from './Pages/Homepage';
import GiftList from './Pages/GiftList';
import AdminPage from './Pages/AdminPage';
import Faq from './Pages/faq';
import Register from './Pages/Register';
import Login from './Pages/Login';
import 'bootstrap/dist/css/bootstrap.min.css';
  
  function App() {
	const router = createBrowserRouter([
	  {
		path: '/',
		element: <GlobalLayout />,
		children: [
		  {
			path: '/',
			element: <HomePage />
		  },
		  {
			path: '/faq',
			element:<Faq />
		  },
		  {
			path: '/gift-list',
			element: <GiftList/>
		  },
		  {
			path: '/register',
			element: <Register/>
		  },
		  {
			path: '/login',
			element: <Login/>
		  },
		  {
			path: '/admin',
			element: <AdminPage />
		  }
		]
	  }
	]);
  
	return (
	  <div className="App">
		<RouterProvider router={router} />
	  </div>
	);
  }
  
  export default App;