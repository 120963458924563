import React, { createContext, useContext, useState, useMemo, useEffect } from "react";
import { loginUser, verifyUser } from "./AuthUtils";
import { setUserToken, getUserToken, removeUserToken } from "./authLocalStorage";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(getUserToken()); // Initialize user with token from storage
    const [isVerified, setIsVerified] = useState(false);
    const [shouldRefresh, setShouldRefresh] = useState(false); // Correct the naming convention

    // Function to update user token
    const updateUserToken = (token) => {
        setUserToken(token);
        setUser(token);
    };

    // Function to handle login
    const login = async (userData) => {
        try {
            const response = await loginUser(userData);
            if (response && response.token) {
                updateUserToken(response.token);
                return response;
            } else {
                throw new Error("Login failed");
            }
        } catch (error) {
            console.error("Login error:", error);
            throw error;
        }
    };

    // Function to handle logout
    const logout = () => {
        removeUserToken();
        setUser(null);
        setIsVerified(false);
    };

    // Verify user token on mount and when shouldRefresh changes
    useEffect(() => {
        const verifyCurrentUser = async () => {
            if (user) {
                try {
                    const verified = await verifyUser(user);
                    setIsVerified(verified);
                } catch (error) {
                    console.error("Verification error:", error);
                }
            }
        };

        verifyCurrentUser();
    }, [user, shouldRefresh]);

    const value = useMemo(() => ({
        user,
        logout,
        login,
        isVerified,
        setShouldRefresh
    }), [user, isVerified]);

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    );
};

const useAuth = () => useContext(AuthContext);

export { AuthProvider, AuthContext, useAuth };