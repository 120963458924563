import React from 'react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/autoplay';

const CarouselTwo = () => {
  return (
    <Swiper
      modules={[Navigation, Pagination, Scrollbar, A11y]}
      spaceBetween={5}
      slidesPerView={3}
      loop={true}
      navigation
      autoplay={true}
      pagination={{ clickable: true }}
      scrollbar={{ draggable: true }}
      onSwiper={(swiper) => console.log(swiper)}
      onSlideChange={() => console.log('slide change')}
    >
      <SwiperSlide><div className="carouselPics"><img src="https://i.imgur.com/YeBH5OT.jpg" alt=""/></div></SwiperSlide>
      <SwiperSlide><div className="carouselPics"><img src="https://i.imgur.com/dI9IxhE.jpg" alt=""/></div></SwiperSlide>
      <SwiperSlide><div className="carouselPics"><img src="https://i.imgur.com/WKTxY6w.jpg" alt=""/></div></SwiperSlide>
      <SwiperSlide><div className="carouselPics"><img src="https://i.imgur.com/zNpA9Vi.jpg" alt=""/></div></SwiperSlide>
      <SwiperSlide><div className="carouselPics"><img src="https://i.imgur.com/QYykUpy.jpg" alt=""/></div></SwiperSlide>
      <SwiperSlide><div className="carouselPics"><img src="https://i.imgur.com/nNNa8po.jpg" alt=""/></div></SwiperSlide>
      <SwiperSlide><div className="carouselPics"><img src="https://i.imgur.com/lNOCdDt.jpg" alt=""/></div></SwiperSlide>
      <SwiperSlide><div className="carouselPics"><img src="https://i.imgur.com/D7C6pZH.jpg" alt=""/></div></SwiperSlide>
      <SwiperSlide><div className="carouselPics"><img src="https://i.imgur.com/a1tCzX0.jpg" alt=""/></div></SwiperSlide>
      <SwiperSlide><div className="carouselPics"><img src="https://i.imgur.com/ZZffonD.jpg" alt=""/></div></SwiperSlide>
    </Swiper>
  );
};

export default CarouselTwo;
