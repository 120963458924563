import { useState } from "react";
import { useAuth } from "../Hooks/AuthContext";
import { useNavigate } from "react-router-dom";
import { registerUser } from "../Hooks/AuthUtils";
import Card from 'react-bootstrap/Card';

const Register = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  
    const auth = useAuth();
    const navigate = useNavigate();

  return (
    <div className="registration-container">
      <Card id='registerCard'>
        <Card.Body>
          <h1 className="registration-title">Register</h1>
            <div>
              <label id="registerEmail">Email:</label>
              <input
                type="text"
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div>
              <label id="registerPassword">Password:</label>
              <input
                type="password"
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <br/>
            <button
                onClick={async () => {
                const users = {email, password}
                const registerResult = await registerUser(users)
                if (registerResult.success) {
				navigate("/login");
          }
        }}
      >
        Signup
      </button>
        </Card.Body>
      </Card>
    </div>
  );
};

export default Register;