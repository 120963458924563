import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../Hooks/AuthContext";
import Card from 'react-bootstrap/Card';

export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { login } = useAuth();
  const navigate = useNavigate();
  const [error, setError] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent default form submission behavior
    setError(""); // Clear any previous errors
    const userData = { email, password };

    try {
      const loginResult = await login(userData);
      if (loginResult && loginResult.token) {
        navigate("/admin");
      } else {
        setError("Invalid login credentials.");
      }
    } catch (err) {
      setError(err.message || "Login failed. Please check your email and password.");
    }
  };

  return (
    <div id='login-container'>
      <Card id="loginCard">
        <Card.Body>
          <h1 className="login-title">Login</h1>
          <form onSubmit={handleSubmit}> {/* Use form with onSubmit */}
            <div>
              <label htmlFor="email">Email:</label>
              <input
                type="text"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="password">Password:</label>
              <input
                type="password"
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            {error && <p className="text-danger">{error}</p>}
            <button type="submit" className="btn btn-success btn-block"> {/* Change button type to submit */}
              Login
            </button>
          </form>
        </Card.Body>
      </Card>
    </div>
  );
}