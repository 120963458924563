import React from 'react';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import CarouselTwo from '../Components/CarouselTwo';

function GiftList({ gifts }) {
  const renderGiftList = (category) => {
    return Array.isArray(gifts[category]) ? (
      gifts[category].map((gift, index) => (
        <div key={index}>
          <ListGroup.Item
            style={{
              textDecoration: gift.isStruckOut ? 'line-through' : 'none',
            }}
          >
            {`${gift.Name} - ${gift.Description}`}
          </ListGroup.Item>
          {index < gifts[category].length - 1 && <hr />}
        </div>
      ))
    ) : (
      <p>No gifts to display.</p>
    );
  };

  return (
    <div id='giftDiv'>
      <Container id='giftContainer'>
        <div id='spaceAboveGiftCard'></div>
        <Row>
          <Col xs={12} md={6} lg={4} className="mb-3">
            <Card id='giftCard' style={{ width: '100%' }}>
              <Card.Body>
                <Card.Title>Men's</Card.Title>
              </Card.Body>
              {renderGiftList('mens')}
            </Card>
          </Col>
          <Col xs={12} md={6} lg={4} className="mb-3">
            <Card id='giftCard' style={{ width: '100%' }}>
              <Card.Body>
                <Card.Title>Women's</Card.Title>
              </Card.Body>
              {renderGiftList('womens')}
            </Card>
          </Col>
          <Col xs={12} md={6} lg={4}>
            <Card id='giftCard' style={{ width: '100%' }}>
              <Card.Body>
                <Card.Title>General</Card.Title>
              </Card.Body>
              {renderGiftList('general')}
            </Card>
          </Col>
        </Row>
        <br />
        <br />
        <CarouselTwo />
        <br />
        <br />
      </Container>
    </div>
  );
}

export default GiftList;
