import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { useLocation } from 'react-router-dom';

function NavBar () {

  const location = useLocation(); // Get the current location
  const currentPath = location.pathname;

  const isActive = (path) => {
    return currentPath === path ? 'active' : '';
  };

  return (
    <Navbar id='navBar' expand="lg">
      <Container>
        <Navbar.Brand href="/" style={{fontSize:'24px'}}>Peaceful Pastures Project</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link className={`navLink ${isActive('/')}`} href="/" style={{ marginLeft: '80px' }}>
            Home
            </Nav.Link>
            <Nav.Link className={`navLink ${isActive('/gift-list')}`} href="/gift-list" style={{ marginLeft: '40px' }}>
            Gift List
            </Nav.Link>
            <Nav.Link className={`navLink ${isActive('/faq')}`} href="/faq" style={{ marginLeft: '40px' }}>
            FAQ
            </Nav.Link>
            <Navbar.Brand id='navSleigh' href="/login">
            <img
              src="https://i.imgur.com/zA7C0Kr.png"
              width="100"
              height="60"
              alt="Christmas-Sleigh"
            />
          </Navbar.Brand>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavBar;